import Vue from 'vue'
import Vuex from 'vuex'

import settings from '../settings'
//modules
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    settings
  },
  actions: {},
  modules: {
    auth,
  }
})
