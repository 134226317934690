import Vue from 'vue'
// import VueSocketIO from 'vue-socket.io'
import VueSocketIOExt from 'vue-socket.io-extended'
import io from 'socket.io-client'
import store from '../store'

import settings from '../settings'

const socket = io(settings.baseUrl, {
  autoConnect: false
})

Vue.use(VueSocketIOExt, socket, { store })

/*
Vue.use(new VueSocketIO({
  debug: true,
  connection: socket,
  vuex: {
    store,
    actionPrefix: 'socket_',
    mutationPrefix: 'SOCKET_'
  }
}))
*/

export default socket
