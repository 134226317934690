import validation from 'vee-validate/dist/locale/tr'

export default {
  validations: validation.messages,
  languageNames: {
    en: 'English (EN)',
    tr: 'Türkçe (TR)',
  },
  fields: {
    email: 'Email',
    password: 'Parola',
  },
}
