export default {
  baseUrl: process.env.VUE_APP_API_URL,
  multiLanguage: false,
  defaultLanguage: 'en',
  dark: true,
  theme: {
    primary: '#CC0000',
    accent: '#666666',
  },
}
