import axios from '../plugins/axios'
import socket from '../plugins/socket'
import { sleep } from '../lib/helpers'

export default {
  namespaced: true,
  state: () => {
    return {
      isLoading: true,
      token: '',
      user: null,
    }
  },
  getters: {
    isAuthenticated: (s) => !!s.token,
    isAdmin: (s) => (s.user?.role === 'admin'),
  },
  mutations: {
    AUTH_REQUEST (state) {
      state.isLoading = true
    },
    AUTH_SUCCESS (state, token) {
      state.isLoading = false
      state.token = token
    },
    AUTH_LOGOUT (state) {
      state.isLoading = false
      state.token = null
      state.user = null
    },
    AUTH_SET_USER (state, user) {
      state.user = user
    },
  },
  actions: {
    async login ({ commit, dispatch }, loginData) {
      commit('AUTH_REQUEST')
      return new Promise((resolve) => {
        socket.io.opts.query = { token: loginData.password, type: loginData.type || 'webclient', name: loginData.name }
        socket.open()
        socket.once('connect', () => {
          console.log('connected on vuex action')
          commit('AUTH_SUCCESS', socket.io.opts.query.token)
          sessionStorage.setItem('clicker:token', socket.io.opts.query.token)
          sessionStorage.setItem('clicker:name', socket.io.opts.query.name)
          sessionStorage.setItem('clicker:type', socket.io.opts.query.type)
          sleep(1000).then(() => {
            return dispatch('getUser')
          }).then(resolve)
        })
      })
    },
    async getUser({ commit }) {
      const { data } = await axios.get(`/socket/${socket.id}`)
      commit('AUTH_SET_USER', data)
      return
    },
    async logout ({ commit }) {
      // localStorage.removeItem(tokenKey)
      // delete axios.defaults.headers.common['Authorization']
      socket.io.opts.query = null
      socket.close()
      sessionStorage.removeItem('clicker:token')
      sessionStorage.removeItem('clicker:name')
      sessionStorage.removeItem('clicker:type')
      commit('AUTH_LOGOUT')
    },
    async checkAuthStatus ({ state, commit, dispatch }) {
      const data = {
        token: sessionStorage.getItem('clicker:token'),
        type: sessionStorage.getItem('clicker:type'),
        name: sessionStorage.getItem('clicker:name'),
      }

      if (!state.token) {
        if (data.token) {
          await dispatch('login', {
            password: data.token,
            type: data.type,
            name: data.name
          })
        } else {
          commit('AUTH_LOGOUT')
        }
      }
    },
  }
}
