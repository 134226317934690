import validation from 'vee-validate/dist/locale/en'

export default {
  validations: validation.messages,
  languageNames: {
    en: 'English (EN)',
    tr: 'Türkçe (TR)',
  },
  fields: {
    email: 'Email',
    password: 'Password',
    name: 'Name',
  },
  login: {
    title: 'Login',
    email_label: 'Email',
    name_label: 'Name',
    password_label: 'Password',
    login: 'LOGIN',
  },
  auth: {
    logout: 'Logout',
  },
}
