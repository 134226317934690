export default async ({to, next, store }) => {
  // await checkLoggingIn(store)
  console.log('to:', to)
  await store.dispatch('auth/checkAuthStatus')
  const isAuthenticated = !!store.state.auth.user
  const isAdmin = store.getters['auth/isAdmin']

  if (to.matched.some(i => i.meta.guest)) {
    if (isAuthenticated) {
      next({ path: '/' })
    } else {
      next()
    }
  } else if (to.matched.some(i => i.meta.auth === false)) {
    next()
  } else {
    if (!isAuthenticated) {
      next({
        path: '/login',
        params: { redirect: to.fullPath }
      })
    } else {
      if (to.matched.some(i => i.meta.admin === true) && !isAdmin) {
        next({ path: '/' })
      }
      next()
    }
  }
}
