<template>
  <router-view />
</template>

<script>
export default {
  name: 'Clicker',
  head () {
    return {
      title: 'Clicker',
      titleTemplate: '%s | Clicker'
    }
  },
  sockets: {
    'app_refresh' () {
      window.location.reload()
    },
  },
  created () {

  }
}
</script>

<style>
</style>
